<template>
  <div v-if="getReportDetail" class="report-detail-wrap">
    <div class="d-flex" style="justify-content: space-between">
      <div class="back-to-list-text cus-is-button" @click="$router.go(-1)">
        <el-icon style="margin-right: 18px"><Back /></el-icon>
        返回列表
      </div>
      <el-select v-model="selectClient" style="width: 110px" @change="handleSelectClient($event)">
        <el-option label="統計" value="" />
        <el-option
          v-for="(client, index) in getClientList"
          :key="index"
          :label="`機台${client.client_id}`"
          :value="client.client_id"
        />
      </el-select>
    </div>
    <el-tabs v-model="activeName" style="margin-top: 30px" :before-leave="handleClickTab">
      <el-tab-pane v-for="(section, index) in sectionList" :key="index" :label="section.label" :name="section.value">
        <div ref="table" class="table-wrap">
          <div
            v-for="(obj, index) in Object.keys(details)"
            :key="index"
            class="box-bg"
            :style="`width:${details[obj].width || 100}%`"
          >
            <div :class="['title', details[obj].class]">{{ details[obj].title }}</div>
            <div :class="['item-wrap', details[obj].class]">
              <div
                v-for="(item, index) in details[obj].items"
                :key="index"
                :class="['item', item.color]"
                :style="`width: ${item.width || 25}%`"
              >
                <!-- label欄位名稱 -->
                <div>
                  <p>{{ item.label }}</p>
                  <small>{{ item.comment }}</small>
                </div>
                <!-- value欄位值 -->
                <!-- 不顯示在統計的欄位 -->
                <span v-if="item.no_statistics && !selectClient">請查看各機台</span>
                <!-- 營業日期 -->
                <span v-else-if="item.value === 'date'">{{ formatDateTime('YYYY/MM/DD', getReportDetail.date) }}</span>
                <!-- 銷售淨總額 -->
                <span v-else-if="item.value === 'sales_total'">
                  {{
                    getReportDetail.order_total_amount +
                    getReportDetail.redundant_cash -
                    getReportDetail.order_total_discount -
                    getReportDetail.total_discount
                  }}
                </span>
                <!-- 發票總金額 -->
                <span v-else-if="item.value === 'invoice_total'">
                  {{
                    getReportDetail.invoice_total_sales_amount +
                    getReportDetail.invoice_total_tax_amount +
                    getReportDetail.invoice_total_free_tax_amount
                  }}
                </span>
                <!-- 應收現金 -->
                <span v-else-if="item.value === 'need_cash'">
                  {{ needCash }}
                </span>
                <!-- 現金差額 -->
                <span v-else-if="item.value === 'balance'">
                  {{ getReportDetail.cash_box - needCash }}
                </span>
                <!-- 其餘全部欄位值 -->
                <span v-else>{{ item.value ? getReportDetail[item.value] : '' }}</span>
              </div>
            </div>
          </div>
        </div>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>
<script>
import { useRoute, useRouter } from 'vue-router';
import { ref, watch, reactive, computed } from 'vue';
import { useGetters, useMutations, useActions } from '@/utils/mapStore';
import { formatDateTime } from '@/utils/datetime';
export default {
  name: 'report-detail',
  setup() {
    const router = useRouter();
    const route = useRoute();
    const table = ref(null);
    const screenHeight = document.documentElement.clientHeight;
    const contentHeight = ref(screenHeight - 400 + 'px');
    const details = reactive({
      transaction: {
        title: '營業資訊',
        items: [
          { label: '門市名稱', value: 'shop_name' },
          { label: '交易序號（起）', value: 'start_serial_number', no_statistics: true },
          { label: '發票號碼（起）', value: 'start_invoice_no', no_statistics: true },
          {},
          { label: '營業日期', value: 'date' },
          { label: '交易序號（迄）', value: 'end_serial_number', no_statistics: true },
          { label: '發票號碼（迄）', value: 'end_invoice_no', no_statistics: true },
        ],
      },
      sales: {
        title: '銷售統計',
        items: [
          { label: '訂單筆數', value: 'order_count' },
          { label: '銷售數−產品數', value: 'order_product_count' },
          { label: '自備杯獎勵(現金)筆數', value: 'event_cash_count' },
          { label: '自備杯獎勵(旺卡)筆數', value: 'event_card_count' },
          { label: '銷售數−杯數', value: 'order_item_count' },
          { label: '銷售數−周邊商品', value: 'order_other_product_count' },
          { label: '自備杯獎勵(現金)金額', value: 'event_cash_amount' },
          { label: '自備杯獎勵(旺卡)金額', value: 'event_card_amount' },
        ],
      },
      dayungs_pay: {
        width: 26,
        title: '大苑子支付',
        class: 'fix-right-padding',
        items: [
          { label: '旺卡消費', value: 'want_card', width: 100 },
          { label: '黃金點數', value: 'want_card_point', width: 100 },
          { label: '現金抵用卡', value: 'cash_card', width: 100 },
          { label: '外送行動支付', value: 'payment_by_outside_payment_app', width: 100 },
        ],
      },
      other_pay: {
        width: 74,
        title: '其他支付',
        class: 'fix-left-padding',
        items: [
          { label: '現金', value: 'cash', width: 33.33 },
          { label: 'Line Pay', value: 'line_pay', width: 33.33 },
          { label: '街口支付', value: 'jeko', width: 33.33 },
          { label: '台灣Pay', value: 'taiwan_pay', width: 33.33 },
          { label: '支付寶', value: 'ali_pay', width: 33.33 },
          { label: '悠遊付', value: 'uupay', width: 33.33 },
          { label: '信用卡', value: 'credit_card', width: 33.33 },
          { label: '一卡通', value: 'i_pass', width: 33.33 },
          { label: '悠遊卡', value: 'easy_card', width: 33.33 },
          { label: 'FoodPanda', value: 'foodpanda', width: 33.33 },
          { label: 'Uber Eats', value: 'uber_eat', width: 33.33 },
          { label: 'Foodomo', value: 'foodomo', width: 33.33 },
          { label: 'line@訂餐', value: 'line', width: 33.33 },
          { label: '禮券', value: 'gift_certificate', width: 33.33 },
          { label: '園區員工卡', value: 'park_employee_card', width: 33.33 },
          { label: 'PandaGo', value: 'panda_go', width: 33.33 },
        ],
      },
      sales_total: {
        title: '銷售總計',
        items: [
          { label: '銷售總金額(+)', value: 'order_total_amount' },
          { label: '折扣金額(−)', value: 'order_total_discount' },
          { label: '折抵金額(−)', value: 'total_discount' },
          { label: '=銷售總淨額', value: 'sales_total', color: 'green' },
          { label: '溢收(+)', value: 'redundant_cash' },
        ],
      },
      invoice: {
        title: '發票總計',
        items: [
          { label: '作廢筆數', value: 'void_invoice_count' },
          { label: '應稅銷售額(+)', value: 'invoice_total_sales_amount' },
          { label: '免稅銷售額(+)', value: 'invoice_total_free_tax_amount' },
          { label: '=發票總金額', value: 'invoice_total', color: 'green' },
          { label: '作廢總金額', value: 'void_invoice_total_amount' },
          { label: '應稅稅額(+)', value: 'invoice_total_tax_amount' },
        ],
      },
      note: {
        title: '其他記錄',
        items: [
          { label: '旺卡加值', value: 'want_card_add' },
          { label: '掛單更正商品數', value: 'order_item_reedit_count' },
          { label: '開櫃次數', value: 'open_cash_box_count' },
          {},
          { label: '旺卡退值', value: 'want_card_refund' },
          { label: '掛單更正總金額', value: 'order_item_reedit_amount' },
        ],
      },
      check: {
        title: '現金核對',
        items: [
          { label: '應收現金(+)', value: 'current_cash' },
          { label: '雜支(−)', value: 'other_expense' },
          { label: '=抽屜應有現金', value: 'need_cash', color: 'green' },
        ],
      },
    });
    //偵測沒有資料
    const { getReportList, getClientList, getCurrentIndex, getReportDetail } = useGetters('report', [
      'getReportList',
      'getClientList',
      'getCurrentIndex',
      'getReportDetail',
    ]);
    //應收現金(抽屜應有現金+雜支-零用金)
    const needCash = computed(() => getReportDetail.value?.current_cash - getReportDetail.value?.other_expense);
    const CLEAR_REPORT_DETAIL = useMutations('report', ['CLEAR_REPORT_DETAIL']);
    if (!getReportDetail.value) router.push('/order/report?page=1');
    watch(route, () => {
      if (!route.fullPath.includes('/detail')) CLEAR_REPORT_DETAIL();
    });
    //點擊機台
    const selectClient = ref('');
    const oldClient = ref('');
    const activeName = ref('day');
    const sectionList = ref([{ label: '日結班表', value: 'day' }]);
    const doGetReportDetail = useActions('report', ['doGetReportDetail']);
    const SET_REPORT_DETAIL = useMutations('report', ['SET_REPORT_DETAIL']);
    watch(selectClient, (val, oldVal) => {
      oldClient.value = oldVal;
    });
    const handleSelectClient = async (client_id) => {
      try {
        if (client_id) {
          const data = {
            shop_id: getReportDetail.value.shop_id,
            date: getReportDetail.value.date,
            client_id: client_id,
            section_id: 0,
          };
          await doGetReportDetail(data);
          activeName.value = 'day';
          sectionList.value.splice(1);
          const index = getClientList.value.findIndex((e) => e.client_id === selectClient.value);
          for (let i = 1; i <= Number(getClientList.value[index].section_id); i++) {
            sectionList.value.push({ label: `${i < 10 ? '0' + i : i}班`, value: `${i < 10 ? '0' + i : i}` });
          }
          tableScrollTop();
        } else {
          SET_REPORT_DETAIL(getReportList.value[getCurrentIndex.value]);
          activeName.value = 'day';
          sectionList.value.splice(1);
          tableScrollTop();
        }
      } catch {
        selectClient.value = oldClient.value;
      }
    };
    //點擊班號tab
    const handleClickTab = async (activeName) => {
      try {
        if (selectClient.value) {
          const data = {
            shop_id: getReportDetail.value.shop_id,
            date: getReportDetail.value.date,
            client_id: selectClient.value,
            section_id: activeName === 'day' ? 0 : activeName,
          };
          await doGetReportDetail(data);
        }
        tableScrollTop();
        return Promise.resolve(true);
      } catch {
        return Promise.reject(false);
      }
    };
    //計算抽屜應有現金與實際抽屜現金
    watch(
      getReportDetail,
      () => {
        if (!getReportDetail.value) return;
        const cash_box = getReportDetail.value.cash_box;
        if (details.check.items.length > 3) details.check.items = details.check.items.slice(0, 3);
        if (cash_box === needCash.value) {
          details.check.items.push({ label: '=實際抽屜現金', value: 'cash_box', color: 'green' });
        } else {
          details.check.items.push(
            { label: '≠實際抽屜現金', value: 'cash_box', color: 'red' },
            {},
            {},
            {},
            {
              label: '現金差額',
              value: 'balance',
              color: 'red',
            }
          );
        }
      },
      { immediate: true, deep: true }
    );
    //表格上滑
    const tableScrollTop = () => {
      table.value.forEach((e) => {
        e.scrollTop = 0;
      });
    };
    return {
      formatDateTime,
      table,
      contentHeight,
      details,
      getClientList,
      getReportDetail,
      needCash,
      selectClient,
      sectionList,
      activeName,
      handleSelectClient,
      handleClickTab,
    };
  },
};
</script>
<style lang="scss" scoped>
.report-detail-wrap {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: scroll;
  @include white-bg;
  padding: 22px;
}
.table-wrap {
  width: 100%;
  border: 1px solid $el-border-color-lighter;
  margin-top: 30px;
  height: v-bind(contentHeight);
  overflow: scroll;
  @include flex-wrap;
  .box-bg {
    .title {
      width: 100%;
      height: 45px;
      font-weight: 500;
      color: $color-text-light;
      background-color: $color-background;
      line-height: 45px;
      padding: 0 20px;
    }
    .item-wrap {
      @include flex-wrap;
      padding: 8px 20px;
      .item {
        padding: 8px 50px 8px 0;
        @include flex-between-center;
      }
      .green {
        color: $color-green;
      }
      .red {
        color: $color-danger;
      }
    }
    .fix-right-padding {
      padding-right: 0;
    }
    .fix-left-padding {
      padding-left: 0;
    }
  }
}
</style>
